@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
}

body {
  font-family: "JetBrains Mono", monospace;
}

.removeScrollbarClass::-webkit-scrollbar {
  display: none;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

.pixel {
  font-family: "Press Start 2P", system-ui;
}

.mono {
  font-family: "JetBrains Mono", monospace;
  font-weight: 200;
  color: #5c5cff;
}